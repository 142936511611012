import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import Images from '../utils/Images';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {isoToDate, priceFormat} from '../inc/helper';

class NewCreditApplyModule extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,

      adverts: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  getAdverts(currentPage) {
      const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };
  
    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;
  
    fetch(config.restApi + `myAdverts/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
            this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  handlePageClick(page) {
    //page.selected : index 0'dan başlıyor
    this.setState({currentPage: page.selected + 1}, () => this.getAdverts(this.state.currentPage))
  }

  advertStatus(id, title) {
    if(id === 1) {
      return 'Onay Bekliyor'
    } else if(id === 2) {
      return 'Yayında'
    } else if(id === 3) {
      return 'Reddedildi'
    } else if(id === 4) {
      if (title) {
        return 'Pasif'
      } else {
        return 'Taslak'
      }
    } else {
      return '-'
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="ibox">
        <div className="ibox-title">
          <strong className="text-black">Aktif İlanlarım</strong>
        </div>
        <div className="table-responsive-lg mb-5">
          <table className="table table-bordered text-center">
            <thead>
              <tr className="text-black">
                <th>İlan Görseli</th>
                <th>İlan Sahibi</th>
                <th>İlan Adı</th>
                <th>Fiyat</th>
                <th>İlan Tarihi</th>
                <th>Bölge</th>
                <th>İlan Durumu</th>
                <th>Kredi Başvuru</th>
              </tr>
            </thead>
            <tbody>
            {this.state.adverts.map((advertsInfo) =>
              <tr key={advertsInfo.id}>
                <td className="align-middle">
                  {advertsInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertsInfo.photos[0].path}`}/>
                  : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertsInfo.id}</strong>
                </td>
                <td className="align-middle">{advertsInfo.memberFullName}</td>
                <td className="align-middle">{advertsInfo.title ? advertsInfo.title : "İlan başlığı girilmedi"}</td>
                <td className="align-middle">{priceFormat(advertsInfo.price)} {advertsInfo.currencySymbol}</td>
                <td className="align-middle">{isoToDate(advertsInfo.addedDate)}</td>
                <td className="align-middle">{advertsInfo.cityName + '/' + advertsInfo.townName}</td>
                <td className="align-middle">{this.advertStatus(advertsInfo.advertStatus, advertsInfo.title)}</td>
                <td className="align-middle">{advertsInfo.title && (advertsInfo.advertStatus === 2 || advertsInfo.advertStatus === 4) ? <Link className="btn btn-outline btn-green text-center mb-1" to={'/creditProviders?id=' + advertsInfo.id} title="Başvuru Yap"><i className="fa fa-plus"></i></Link> : <Link className="btn btn-outline border-darkblue text-center mb-1" title="Sadece yayında veya pasif durumdaki ilan için başvuru yapabilirsiniz."><i className="fa fa-plus border-darkblue"></i></Link>}</td>
              </tr>
            )}
            {this.state.adverts.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="8">Aktif İlan Listesi Bulunamadı !</td></tr>}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"Geri"}
          nextLabel={"İleri"}
          breakLabel={"..."}  
          pageCount={this.state.pageCount}
          marginPagesDisplayed={1}
          onPageChange={page => this.handlePageClick(page)}
          pageRangeDisplayed={2}
          containerClassName={'pagination'}
          activeClassName={'activePage'}
          />
      </div>
  }
}

export default function NewCreditApply(props) {
  return (
    <NewCreditApplyModule navigate={useNavigate()}></NewCreditApplyModule>
  )
}