import React from 'react';

class CustomerServiceModule extends React.Component {
  render() {
    return (
      <div className="row d-flex justify-content-center align-items-center customerServiceImage">
        <div className="col text-center p-0">
          <h1 className="font-arial text-black text-h2">MÜŞTERİ</h1>
          <h1 className="font-arial text-black font-bold text-h1">HİZMETLERİ</h1>
          <div className="d-block">
            <h1 className="font-bold text-black text-h3">İletişim Numarası</h1>
            <h2 className="font-normal font-italic text-black text-h4">+90 (212) 706 09 20</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default function CustomerService() {
  return (
    <CustomerServiceModule />
  )
}