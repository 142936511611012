import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import Images from '../utils/Images';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {isoToDateTime} from '../inc/helper';
import { getCookie } from '../inc/cookies';

class CreditRequestModule extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,

      creditRequests: [],
      filterFullName: '',
      filterProviderName: '',
      filterCreditStatus: '',
      filterAdvertId: '',
      filterAddedStartDate: '',
      filterAddedEndDate: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getCreditRequests(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  filteredCreditRequests(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const query = `limit=${this.state.limit}&page=${currentPage}&filterAdvertId=${this.state.filterAdvertId}&filterFullName=${this.state.filterFullName}&filterProviderName=${this.state.filterProviderName}&filterCreditStatus=${this.state.filterCreditStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}`;

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  fetch(config.restApi + `creditRequests/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentCreditRequests = responseData.creditRequests.slice(startIndex, endIndex);
          this.setState({isLoading: false, creditRequests: currentCreditRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
}

  getCreditRequests(currentPage) {
      const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `creditRequests/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200:
            const currentCreditRequests = responseData.creditRequests.slice(startIndex, endIndex);
            this.setState({isLoading: false, creditRequests: currentCreditRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.filteredCreditRequests(this.state.currentPage))
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  getCreditStatusName(creditRequestStatus) {
    if(creditRequestStatus === 1){
      return <strong className="font-bold text-warning">Onay Bekliyor</strong>
    } else if(creditRequestStatus === 2){
      return <strong className="font-bold text-green">Onaylandı</strong>
    } else if(creditRequestStatus === 3){
      return <strong className="font-bold text-danger">Reddedildi</strong>
    } else if(creditRequestStatus === 4){
      return <strong className="font-bold text-danger">İptal Edildi</strong>
    } else if(creditRequestStatus === 5){
      return <strong className="font-bold text-success">İşleme Alındı</strong>
    } else if(creditRequestStatus === 6){
      return <strong className="font-bold text-warning">Evrak Bekleniyor</strong>
    } else if(creditRequestStatus === 7){
      return <strong className="font-bold text-warning">Eksik Evrak Bekleniyor</strong>
    } else if(creditRequestStatus === 8){
      return <strong className="font-bold text-warning">Kefil Bekleniyor</strong>
    } else if(creditRequestStatus === 9){
      return <strong className="font-bold text-warning">Onay Sürecinde</strong>
    }

    return '-'
  }

  filterOnClick = (event) => {
    const query = `filterAdvertId=${this.state.filterAdvertId}&filterFullName=${this.state.filterFullName}&filterProviderName=${this.state.filterProviderName}&filterCreditStatus=${this.state.filterCreditStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}`;
    this.filteredCreditRequests(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterFullName: '',
      filterProviderName: '',
      filterCreditStatus: '',
      filterAdvertId: '',
      filterAddedStartDate: '',
      filterAddedEndDate: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCreditRequests(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterAdvertId=${this.state.filterAdvertId}&filterFullName=${this.state.filterFullName}&filterProviderName=${this.state.filterProviderName}&filterCreditStatus=${this.state.filterCreditStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCreditRequests(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  filterProviderName = (event) => {
    this.setState({filterProviderName: event.target.value});
  }

  filterCreditStatus = (event) => {
    this.setState({filterCreditStatus: event.target.value});
  }

  render() {
  return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
      <div className="sk-spinner sk-spinner-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </div>
    :
    <div className="row custom-container">
      <div className="col-xl-9">
        <div className="ibox">
          <div className="ibox-title">
            <h5 className="text-black">Kredi Başvurularım</h5>
          </div>
          <div className="table-responsive-lg">
            <table className="table table-bordered text-center">
              <thead>
                <tr className="text-black">
                  <th>İlan Görseli</th>
                  <th>İlan Başlığı</th>
                  <th>Başvuru Sahibi</th>
                  <th>Kredi Sağlayıcısı</th>
                  <th>Başvuru Tarihi</th>
                  <th>İşlem Tarihi</th>
                  <th>Kredi Durumu</th>
                  <th>Detay</th>
                  <th>Mesaj</th>
                </tr>
              </thead>
              <tbody>
              {this.state.creditRequests.map((creditRequestInfo) =>
                  <tr key={creditRequestInfo.id}>
                    <td className="align-middle">
                      {creditRequestInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${creditRequestInfo.photos[0].path}`}/>
                      : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{creditRequestInfo.advertId}</strong>
                    </td>
                    <td className="align-middle">{creditRequestInfo.advertTitle}</td>
                    <td className="align-middle">{creditRequestInfo.memberFullName}</td>
                    <td className="align-middle">{creditRequestInfo.providerName}</td>
                    <td className="align-middle">{isoToDateTime(creditRequestInfo.addedDate)}</td>
                    <td className="align-middle">{isoToDateTime(creditRequestInfo.modifiedDate)}</td>
                    <td className="align-middle">{this.getCreditStatusName(creditRequestInfo.creditRequestStatus)}</td>
                    <td className="align-middle"><Link className="btn btn-outline btn-green text-center mb-1" to={'/creditRequestDetail?id=' + creditRequestInfo.id} title="Başvuru Detayları"><i className="fa fa-eye"></i></Link></td>
                    <td className="align-middle"><Link className="btn btn-outline btn-green text-center mb-1" to={'/creditRequestHistories?id=' + creditRequestInfo.id} title="Başvuru Yap"><i className="fa fa-envelope"></i></Link></td>
                  </tr>
                )}
              {this.state.creditRequests.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="9">Kredi Başvuru Listesi Bulunamadı !</td></tr>}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Geri"}
            nextLabel={"İleri"}
            breakLabel={"..."}  
            pageCount={this.state.pageCount}
            marginPagesDisplayed={1}
            onPageChange={page => this.handlePageClick(page)}
            pageRangeDisplayed={2}
            containerClassName={'pagination'}
            activeClassName={'activePage'}
          />
        </div>
      </div>
      <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">  
                <strong>İlan No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAdvertId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan No" type="text" value={this.state.filterAdvertId}/>
                <strong>Kredi Sağlayıcısı</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={this.filterProviderName} onKeyDown={this.handleKeyPress} value={this.state.filterProviderName}>
                  <option value="">Seçiniz</option>
                  <option value="1">Satışgaranti Kredi</option>
                  <option value="2">Senetli Satış</option>
                  <option value="3">Hızlı başvuru</option>
                </select>
                <strong>Kredi Durumu</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={this.filterCreditStatus} onKeyDown={this.handleKeyPress} value={this.state.filterCreditStatus}>
                  <option value="">Seçiniz</option>
                  <option value="1">Onay Bekliyor</option>
                  <option value="2">Onaylandı</option>
                  <option value="3">Reddedildi</option>
                  <option value="4">İptal Edildi</option>
                </select>
                <strong>Başvuru Sahibi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Başvuru Sahibi" type="text" value={this.state.filterFullName}/>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate}/>
                <strong>Bitiş Tarihi</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterAddedEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3"  onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>İlan No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterAdvertId: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan No" type="text" value={this.state.filterAdvertId}/>
              <strong>Kredi Sağlayıcısı</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterProviderName} onKeyDown={this.handleKeyPress} value={this.state.filterProviderName}>
                <option value="">Seçiniz</option>
                <option value="1">Satışgaranti Kredi</option>
                <option value="2">Senetli Satış</option>
                <option value="3">Hızlı başvuru</option>
              </select>
              <strong>Kredi Durumu</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterCreditStatus} onKeyDown={this.handleKeyPress} value={this.state.filterCreditStatus}>
                <option value="">Seçiniz</option>
                <option value="1">Onay Bekliyor</option>
                <option value="2">Onaylandı</option>
                <option value="3">Reddedildi</option>
                <option value="4">İptal Edildi</option>
              </select>
              <strong>Başvuru Sahibi</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Başvuru Sahibi" type="text" value={this.state.filterFullName}/>
              <strong>Başlangıç Tarihi</strong>
              <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate}/>
              <strong>Bitiş Tarihi</strong>
              <input className="form-control form-input" onChange={event => this.setState({filterAddedEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedEndDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3"  onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  }
}

export default function CreditRequests(props) {
  return (
    <CreditRequestModule navigate={useNavigate()} />
  )
}