import advertImage from '../assets/img/advertImage.jpg';
import Blogo from '../assets/img/Blogo.png';
import customerServiceImage from '../assets/img/customerServiceImage.png';
import logo from '../assets/img/logo.png';
import mainpageImage from '../assets/img/mainpageImage.png';
import userIcon from '../assets/img/userIcon.png'
import Wlogo from '../assets/img/Wlogo.png';
import welcomeImage from '../assets/img/welcomeImage.png';
import messageBackground from '../assets/img/messageBackground.png';

const Images = {
  advertImage,
  Blogo,
  customerServiceImage,
  logo,
  mainpageImage,
  userIcon,
  Wlogo,
  welcomeImage,
  messageBackground
}

export default Images;